<template>
    <div class="container_monitors">
      <b-container class="py-5 px-4 px-md-5">
        <b-row>
          <b-col cols="12" class="mb-4 title">
            <h3 class="fw-bold">Monitors</h3>
            <label>1 of 1 Monitors, <a href="#" class="text-decoration-none">do you need more space?</a></label>
          </b-col>
          <!-- Table -->
          <b-col cols="12" class="d-none d-xl-block">
            <b-table :items="items" :fields="fields" responsive>
              <!-- Input Radio -->
              <template #cell(radio_btn)="data">
                <div class="container-tbody">
                  <b-container fluid>
                    <b-row>
                      <b-col cols="12" class="p-0" align-self="center">
                        <input type="radio">
                      </b-col>
                    </b-row>
                  </b-container>
                </div>
              </template>
              <!-- Name -->
              <template #cell(name)="data">
                <div class="container-tbody">
                  <b-container fluid>
                    <b-row>
                      <b-col cols="12">
                        <label>{{ data.item.name }}</label>
                      </b-col>
                      <b-col cols="12">
                        <a href="#">{{ data.item.url }}</a>
                      </b-col>
                    </b-row>
                  </b-container>
                </div>
              </template>
              <!-- Frequency -->
              <template #cell(frequency)="data">
                <div class="container-tbody">
                  <label>{{ data.item.frequency }}</label>
                </div>
              </template>
              <!-- Measure -->
              <template #cell(measure)="data">
                <div class="container-tbody">
                  <label>{{ data.item.measure }}</label>
                </div>
              </template>
              <!-- Running -->
              <template #cell(running)="data">
                <div class="container-tbody">
                  <b-badge class="running" v-if="data.item.running">Running</b-badge>
                  <b-badge class="paused" v-else>Paused</b-badge>
                </div>
              </template>
              <!-- Actions -->
              <template #cell(actions)="data">
                <div class="container-tbody">
                  <b-button variant="default">
                    <b-img :src="require('@/assets/img/trash.png')" fluid width="25"></b-img>
                  </b-button>
                  <b-button variant="default">
                    <b-img :src="require('@/assets/img/pen.png')" fluid width="20"></b-img>
                  </b-button>
                  <b-button variant="default">
                    <b-img :src="require('@/assets/img/eye.png')" fluid width="30"></b-img>
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-col>

          <!-- Cards -->
          <b-col cols="12" class="mb-4 card-monitor d-xl-none">
            <b-container class="px-0">
              <b-row>
                <b-col cols="auto">
                  <input type="radio">
                </b-col>
                <b-col class="col-card">
                  <b-card no-body>
                    <template #header>
                      <b-col cols="12" class="text-end">
                        <b-badge class="paused ms-auto">Paused</b-badge>
                      </b-col>
                    </template>
                    <b-container class="py-2 body-card">
                      <b-row>
                        <b-col cols="12" class="mb-3">
                          <label class="fw-bold">Do Genius On</label>
                          <p class="mb-0">
                            <a href="#" class="text-decoration-none">https://qaapi.dogeniuson.com/api/login</a>
                          </p>
                        </b-col>
                        <b-col cols="12" class="mb-3">
                          <label class="fw-bold">Frequency</label>
                          <label class="ms-4">2H</label>
                        </b-col>
                        <b-col cols="12">
                          <label class="fw-bold">Measure</label>
                          <label class="ms-4">0.0</label>
                        </b-col>
                      </b-row>
                    </b-container>
                    <template #footer>
                      <b-col cols="12" class="text-end">
                        <b-button variant="default" class="px-2 py-0">
                          <b-img :src="require('@/assets/img/trash.png')" fluid width="25"></b-img>
                        </b-button>
                        <b-button variant="default" class="px-2 py-0">
                          <b-img :src="require('@/assets/img/pen.png')" fluid width="20"></b-img>
                        </b-button>
                        <b-button variant="default" class="px-2 py-0">
                          <b-img :src="require('@/assets/img/eye.png')" fluid width="30"></b-img>
                        </b-button>
                      </b-col>
                    </template>
                  </b-card>
                </b-col>
              </b-row>
            </b-container>
          </b-col>

          <b-col cols="12" class="mb-4 card-monitor d-xl-none">
            <b-container class="px-0">
              <b-row>
                <b-col cols="auto">
                  <input type="radio">
                </b-col>
                <b-col class="col-card">
                  <b-card no-body>
                    <template #header>
                      <b-col cols="12" class="text-end">
                        <b-badge class="paused ms-auto">Paused</b-badge>
                      </b-col>
                    </template>
                    <b-container class="py-2 body-card">
                      <b-row>
                        <b-col cols="12" class="mb-3">
                          <label class="fw-bold">API Monitor</label>
                          <p class="mb-0">
                            <a href="#" class="text-decoration-none">http://qaapi.monitor.com/api/login</a>
                          </p>
                        </b-col>
                        <b-col cols="12" class="mb-3">
                          <label class="fw-bold">Frequency</label>
                          <label class="ms-4">10M</label>
                        </b-col>
                        <b-col cols="12">
                          <label class="fw-bold">Measure</label>
                          <label class="ms-4">0.0</label>
                        </b-col>
                      </b-row>
                    </b-container>
                    <template #footer>
                      <b-col cols="12" class="text-end">
                        <b-button variant="default" class="px-2 py-0">
                          <b-img :src="require('@/assets/img/trash.png')" fluid width="25"></b-img>
                        </b-button>
                        <b-button variant="default" class="px-2 py-0">
                          <b-img :src="require('@/assets/img/pen.png')" fluid width="20"></b-img>
                        </b-button>
                        <b-button variant="default" class="px-2 py-0">
                          <b-img :src="require('@/assets/img/eye.png')" fluid width="30"></b-img>
                        </b-button>
                      </b-col>
                    </template>
                  </b-card>
                </b-col>
              </b-row>
            </b-container>
          </b-col>

          <b-col cols="12" class="mb-4 card-monitor d-xl-none">
            <b-container class="px-0">
              <b-row>
                <b-col cols="auto">
                  <input type="radio">
                </b-col>
                <b-col class="col-card">
                  <b-card no-body>
                    <template #header>
                      <b-col cols="12" class="text-end">
                        <b-badge class="paused ms-auto">Paused</b-badge>
                      </b-col>
                    </template>
                    <b-container class="py-2 body-card">
                      <b-row>
                        <b-col cols="12" class="mb-3">
                          <label class="fw-bold">Name</label>
                          <p class="mb-0">
                            <a href="#" class="text-decoration-none">https://url.com</a>
                          </p>
                        </b-col>
                        <b-col cols="12" class="mb-3">
                          <label class="fw-bold">Frequency</label>
                          <label class="ms-4">5M</label>
                        </b-col>
                        <b-col cols="12">
                          <label class="fw-bold">Measure</label>
                          <label class="ms-4">0.0</label>
                        </b-col>
                      </b-row>
                    </b-container>
                    <template #footer>
                      <b-col cols="12" class="text-end">
                        <b-button variant="default" class="px-2 py-0">
                          <b-img :src="require('@/assets/img/trash.png')" fluid width="25"></b-img>
                        </b-button>
                        <b-button variant="default" class="px-2 py-0">
                          <b-img :src="require('@/assets/img/pen.png')" fluid width="20"></b-img>
                        </b-button>
                        <b-button variant="default" class="px-2 py-0">
                          <b-img :src="require('@/assets/img/eye.png')" fluid width="30"></b-img>
                        </b-button>
                      </b-col>
                    </template>
                  </b-card>
                </b-col>
              </b-row>
            </b-container>
          </b-col>

          <b-col cols="12" class="text-end">
            <b-button variant="primary" class="px-5" :to="{ name: 'monitors.create' }">New</b-button>
          </b-col>
        </b-row>
      </b-container>
    </div>
</template>

<script>
  export default {
    data() {
      return {
        fields: [ {key:'radio_btn', label: ''}, 'name', 'frequency', 'measure', 'running', {key: 'actions', label: ''}],
        items: [
          { name: 'Do Genius On', url: 'https://qaapi.dogeniuson.com/api/login', frequency: '2H', measure: '0.0', running: true},
          { name: 'API Monitor', url: 'http://qaapi.monitor.com/api/login', frequency: '10M', measure: '0.0', running: true},
          { name: 'Name', url: 'https://url.com', frequency: '5M', measure: '0.0', running: true},
        ]
      }
    }
  }
</script>
